import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'

import logo from '../img/sjwsa-client-logo.png'

import Body from '../components/Body';

class BodyContainer extends Component {

	componentDidMount() {

	}

	render() {
		return (
            <div className="wrapper">
                <div className="container" style={{paddingTop: "10px", marginBottom: "30px", paddingBottom: "0px"}}>
                    <div className="row">
                        <div className="col-md-1 col-sm-2 col-3 text-left">
                            <img className="img" src={logo} width="64" height="64" alt="Logo" />
                        </div>
                        <div className="col-md-10 col-sm-10 col-10 text-left text-muted">
                            <h4 className="d-none d-sm-none d-md-block" id="clientForService">{i18next.t("pageTitle")}</h4>
                            <h4 style={{marginLeft: "-7px"}} className="d-none d-sm-block d-md-none" id="clientForService">{i18next.t("pageTitle")}</h4>
                            <h4 className="d-block d-sm-none margin-left-20-font-size" id="clientForService">{i18next.t("pageTitle")}</h4>
                            <span className="d-none d-sm-none d-md-block" id="titleCipherBis">{i18next.t("titleCipherBis")}</span>
                        </div>
                    </div>
                </div>
                <Body />
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BodyContainer);