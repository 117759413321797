import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Tabs from './Tabs'
import Pane from './Tab'

import Fuse from './Fuse'
// import Delete from './Delete'
// import Convert from './Convert'
import Unity from './Unity'

import i18next from 'i18next'

class Body extends Component {

	componentDidMount() {

	}

	render() {
		return (
			<div className="container" style={{paddingTop: "0px"}}>
				
				<div id="allTabsContainer">
					{
						this.props.base.processing
						? <div className="popup-dark">
							<div className="loading-div-inner">
								<i className="fa fa-spinner fa-pulse"></i>
							</div>
						</div>
						: null
					}
					<Tabs selected={0}>
						<Pane label={i18next.t("fuse")}>
							<Fuse />
						</Pane>

						<Pane label={i18next.t("unityTab")}>
							<Unity />
						</Pane>

						{/*<Pane label={i18next.t("delete")}>
							<Delete />
						</Pane>

						<Pane label={i18next.t("convert")}>
							<Convert />
						</Pane>*/}
					</Tabs>
				</div>
			</div>
	    );
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {

    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);