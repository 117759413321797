import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import App from './App'

const Root = ({ store }) => (
  <Provider store={store}>
  	<Routes>
  		<Route path="/" element={<App />} />
  	</Routes>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}
export default Root
