export const setVersion = (data) => (dispatch) => {
    dispatch({
        type: 'SET_VERSION',
        payload: data
    })
}

export const setUrls = (data) => (dispatch) => {
    dispatch({
        type: 'SET_URLS',
        payload: data
    })
}

export const setProcessing = (value) => (dispatch) => {
    dispatch({
        type: 'SET_PROCESSING',
        payload: value
    })
}

export const setSignatureType = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_TYPE',
        payload: value
    })
}

export const setCadesType = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CADES_TYPE',
        payload: value
    })
}

export const setFilelist = (files) => (dispatch) => {
    dispatch({
        type: 'SET_FILELIST',
        payload: files
    })
}

export const setDataFile = (file) => (dispatch) => {
    dispatch({
        type: 'SET_DATAFILE',
        payload: file
    })
}

export const setSignatureTypeDelete = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_TYPE_DELETE',
        payload: value
    })
}

export const setCadesTypeDelete = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CADES_TYPE_DELETE',
        payload: value
    })
}

export const setSignatureTypeConvert = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_TYPE_CONVERT',
        payload: value
    })
}

export const setCadesTypeConvert = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CADES_TYPE_CONVERT',
        payload: value
    })
}

export const setSignatureTypeUnity = (value) => (dispatch) => {
    dispatch({
        type: 'SET_SIGNATURE_TYPE_UNITY',
        payload: value
    })
}

export const setCadesTypeUnity = (value) => (dispatch) => {
    dispatch({
        type: 'SET_CADES_TYPE_UNITY',
        payload: value
    })
}

export const setInputSignatureUnity = (value) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_SIGNATURE_UNITY',
        payload: value
    })
}

export const setInputFileUnity = (value) => (dispatch) => {
    dispatch({
        type: 'SET_INPUT_FILE_UNITY',
        payload: value
    })
}

export const setFileSignsUnity = (value) => (dispatch) => {
    dispatch({
        type: 'SET_FILE_SIGNS_UNITY',
        payload: value
    })
}

export const setResponseSignatureTypeUnity = (value) => (dispatch) => {
    dispatch({
        type: 'SET_RESPONSE_SIGNATURE_TYPE_UNITY',
        payload: value
    })
}

export const setRequestedSignerIndexToRemove = (arr) => (dispatch) => {
    dispatch({
        type: 'SET_REQUESTED_SIGNER_INDEX_TO_REMOVE',
        payload: arr
    })
}

export const setResultedDataUnity = (data) => (dispatch) => {
    dispatch({
        type: 'SET_RESULTED_DATA_UNITY',
        payload: data
    })
}

export const setResultedDataFuse = (data) => (dispatch) => {
    dispatch({
        type: 'SET_RESULTED_DATA_FUSE',
        payload: data
    })
}

export const setJwtToken = (data) => (dispatch) => {
    dispatch({
        type: 'SET_JWT_TOKEN',
        payload: data
    })
}