import React from 'react';
import ReactDOM from 'react-dom/client';
import'react-app-polyfill/stable';
import { BrowserRouter as Router } from 'react-router-dom'
import i18next from 'i18next'
import i18nextXHRBackend from 'i18next-xhr-backend'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import './fonts/metropolis/Metropolis-Thin.otf'


const store = configureStore()
const root = ReactDOM.createRoot(document.getElementById('root'));

i18next
.use(i18nextXHRBackend)
.init({
    lng: 'uk',
    fallbackLng: 'uk',
    debug: true,
    initImmediate: true,
    ns: ['common'],
    defaultNS: 'common',
    backend: {
        loadPath: "../locales/{{lng}}/{{ns}}.json",
        crossDomain: false
    }
}, function (err, t) {
    console.log(i18next.languages);
    if (err) {
        alert("Error while loading localisation library.\nПомилка при завантаженні бібліотеки локалізації.");
    }
    
    root.render(
		<React.StrictMode>
			<Router>
		    	<Root store={store} />
		  	</Router>
		</React.StrictMode>
	);
});
