const defaultState = {
    signatureType: "detached",
    cadesType: "CAdESXLong",
    fileList: [],
    dataFile: null,
    resultedDataFuse: null
}

const fuseReducer = (state = defaultState, { type, payload }) => {

    switch (type) {
        case 'SET_SIGNATURE_TYPE': {
            return {
                ...state,
                signatureType: payload
            }
        }

        case 'SET_CADES_TYPE': {
            return {
                ...state,
                cadesType: payload
            }
        }

        case 'SET_FILELIST': {
            return {
                ...state,
                fileList: payload
            }
        }

        case 'SET_DATAFILE': {
            return {
                ...state,
                dataFile: payload
            }
        }

        case 'SET_RESULTED_DATA_FUSE': {
            return {
                ...state,
                resultedDataFuse: payload
            }
        }

        default:
            return state;
    }

}

export default fuseReducer