import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Cookies from 'universal-cookie';

import { getVersion, getURLs } from '../actions/api';
import { setVersion, setUrls, setJwtToken } from '../actions/localStates';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import '../css/swiss-knife.css';

import BodyContainer from './BodyContainer';

import i18next from 'i18next'

const cookies = new Cookies();

class App extends Component {

	constructor(props) {
    super(props);
		this.goToMainSite = this.goToMainSite.bind(this)
	}

	componentDidMount() {
		var token = cookies.get('caasJwtToken');

		document.title = i18next.t("headerTitle");

		this.props.actions.getVersion()
		.then((response) => {
			this.props.actions.setVersion(response)
		})

		this.props.actions.getURLs()
		.then((response) => {
			this.props.actions.setUrls(response)
			if (token !== undefined && token.length > 0) {
				this.props.actions.setJwtToken(token)
				cookies.remove('caasJwtToken', { path: '/', domain: "." + response.serviceVariables.domain, secure: true, sameSite: 'none' })
			}
		})
	}

	goToMainSite () {
		window.open("https://cipher.com.ua/uk", '_blank').focus();
	}

	render() {
		return (
			<div>
				<BodyContainer />
				<div className="footer text-right" style={{marginRight: "15px", fontSize: "12px", color: "#ebebeb"}}>
				    <label>{i18next.t("version")} {this.props.base.version} build {this.props.base.build}</label>
				    <label onClick={this.goToMainSite} style={{display: "block", cursor: "pointer"}}>@2024 Сайфер</label>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
		getVersion,
		setVersion,
		getURLs,
		setUrls, setJwtToken
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);