const defaultState = {
    signatureType: "DETACHED",
    cadesType: "CADES_X_LONG",
    inputSignature: null,
    inputFile: null,
    signs: [],
    responsedSignatureType: null,
    requestedSignerIndexToRemove: [],
    resultedData: null
}

const unityReducer = (state = defaultState, { type, payload }) => {

    switch (type) {
        case 'SET_SIGNATURE_TYPE_UNITY': {
            return {
                ...state,
                signatureType: payload
            }
        }

        case 'SET_CADES_TYPE_UNITY': {
            return {
                ...state,
                cadesType: payload
            }
        }

        case 'SET_INPUT_SIGNATURE_UNITY': {
            return {
                ...state,
                inputSignature: payload
            }
        }

        case 'SET_INPUT_FILE_UNITY': {
            return {
                ...state,
                inputFile: payload
            }
        }

        case 'SET_FILE_SIGNS_UNITY': {
            return {
                ...state,
                signs: payload
            }
        }

        case 'SET_RESPONSE_SIGNATURE_TYPE_UNITY': {
            return {
                ...state,
                responsedSignatureType: payload
            }
        }

        case 'SET_REQUESTED_SIGNER_INDEX_TO_REMOVE': {
            return {
                ...state,
                requestedSignerIndexToRemove: payload
            }
        }

        case 'SET_RESULTED_DATA_UNITY': {
            return {
                ...state,
                resultedData: payload
            }
        }

        default:
            return state;
    }

}

export default unityReducer