const defaultState = {
    signatureType: "detached",
    cadesType: "CAdESXLong"
}

const convertReducer = (state = defaultState, { type, payload }) => {

    switch (type) {
        case 'SET_SIGNATURE_TYPE_CONVERT': {
            return {
                ...state,
                signatureType: payload
            }
        }

        case 'SET_CADES_TYPE_CONVERT': {
            return {
                ...state,
                cadesType: payload
            }
        }

        default:
            return state;
    }

}

export default convertReducer