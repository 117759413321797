import { combineReducers } from 'redux';

import fuseReducer from './fuseReducer'
import deleteReducer from './deleteReducer'
import convertReducer from './convertReducer'
import unityReducer from './unityReducer'

const baseState = {
    version: "",
    build: "",
    processing: false,
    serviceUrl: "",
    token: null,
    domain: ""
}

const base = (state = baseState, { type, payload }) => {

    switch (type) {
        case 'SET_VERSION': {
            return {
                ...state,
                version: payload.version,
                build: payload.build
            }
        }

        case 'SET_URLS': {
            return {
                ...state,
                serviceUrl: payload.serviceVariables.serviceUrl || "https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1",
                token: (payload.serviceVariables.jwtToken !== null && payload.serviceVariables.jwtToken !== undefined && payload.serviceVariables.jwtToken.length > 0) ? payload.serviceVariables.jwtToken : null,
                domain: "." + payload.domain || ""
            }
        }

        case 'SET_PROCESSING': {
            return {
                ...state,
                processing: payload
            }
        }

        case 'SET_JWT_TOKEN': {
            return {
                ...state,
                token: payload
            }
        }

        default:
            return state;
    }

}

export default combineReducers({
    base: base,
    fuseReducer,
    deleteReducer,
    convertReducer,
    unityReducer
});