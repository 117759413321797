var setHeders

setHeders = function(contentType, useToken) {
  var header;

  switch (contentType) {
  case "json":
		if (useToken !== null && useToken !== undefined) {
			header = {
			  'Content-Type': 'application/json',
			  'Authorization': `Bearer ${useToken}`
			}
		} else {
			header = {
			  'Content-Type': 'application/json'
			}
		}

    break;

  case "blob":
	if (useToken !== null && useToken !== undefined) {
		header = {
			'Content-Type': 'application/octet-stream',
			'Authorization': `Bearer ${useToken}`
		}
	} else {
		header = {
			'Content-Type': 'application/octet-stream'
		}
	}
    break;

  case "text":
	if (useToken !== null && useToken !== undefined) {
		header = {
			'Content-Type': 'text/plain',
			'Authorization': `Bearer ${useToken}`
		}
	} else {
		header = {
			'Content-Type': 'text/plain'
		}
	}
    break;

  default:
	if (useToken !== null && useToken !== undefined) {
		header = {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${useToken}`
		}
	} else {
		header = {
			'Content-Type': 'application/json'
		}
	}
    break;
  }
  return header
}

export const getVersion = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/version.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const getURLs = () => (dispatch) => {
    return fetch(process.env.PUBLIC_URL + "/config.json", {
        method: 'GET',
        dataType: "json"
      }).then(response => {
        return response.json()
    });
}

export const createContext = (url, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context", {
        method: 'POST',
        dataType: "json",
        headers: header
      }).then(response => {
        return response.json()
    });
}

export const uploadContextDataBase64 = (url, uuid, data, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context/" + uuid + "/data", {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const uploadContextDataBinary = (url, uuid, data, token) => (dispatch) => {
    var header = setHeders("blob", token)

    return fetch(url + "/context/" + uuid + "/data", {
        method: 'POST',
        headers: header,
        body: data
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }   
    });
}

export const getDataByIdBase64 = (url, uuid, dataId, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context/" + uuid + "/data/" + dataId + "/base64", {
        method: 'GET',
        dataType: "json",
        headers: header
      }).then(response => {
        return response.json()
    });
}

export const getDataByIdBinary = (url, uuid, dataId, token) => (dispatch) => {
    var header = setHeders("blob", token)

    return fetch(url + "/context/" + uuid + "/data/" + dataId + "/binary", {
        method: 'GET',
        dataType: "blob",
        headers: header
      }).then(response => {
        return response.blob()
    });
}

export const combineSignatures = (url, uuid, data, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context/" + uuid + "/es/combiner", {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        return response.json()
    });
}

export const verifySignature = (url, uuid, data, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context/" + uuid + "/es/verifier", {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        if(response.ok) {
            return response.json()
          } else {
            return response.json().then(json => {
              return {
                contentType: response.headers.get("Content-Type"),
                json: json
              }
            })
          }
    });
}

export const modifySignature = (url, uuid, data, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context/" + uuid + "/es/modifier", {
        method: 'POST',
        dataType: "json",
        headers: header,
        body: JSON.stringify(data)
      }).then(response => {
        if(response.ok) {
            return response.json()
          } else {
            return response.json().then(json => {
              return {
                contentType: response.headers.get("Content-Type"),
                json: json
              }
            })
          }
    });
}

export const deleteContext = (url, uuid, token) => (dispatch) => {
    var header = setHeders("json", token)

    return fetch(url + "/context/" + uuid, {
        method: 'DELETE',
        dataType: "json",
        headers: header
      }).then(response => {
        if (response.ok) {
            return true;
        } else {
            return response.json()
        }
    });
}