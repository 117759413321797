import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import saveAs from 'file-saver';

import { setSignatureTypeUnity, setCadesTypeUnity, setInputFileUnity, setFileSignsUnity, setInputSignatureUnity, setResponseSignatureTypeUnity, setRequestedSignerIndexToRemove,
		 setProcessing, setResultedDataUnity } from '../actions/localStates'
import { createContext, deleteContext, uploadContextDataBinary, getDataByIdBase64, getDataByIdBinary, verifySignature, modifySignature } from '../actions/api'

import i18next from 'i18next'

import Dropzone from 'react-dropzone';
const dropzoneRef = createRef();

class Unity extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	dsInfos: []
	    }

	    this.dsTypeHendler = this.dsTypeHendler.bind(this)
	    this.cadesTypeHendler = this.cadesTypeHendler.bind(this)
	    this.handleOnDrop = this.handleOnDrop.bind(this);
	    this.handleDeleteFile = this.handleDeleteFile.bind(this);

	    this.modifySignature = this.modifySignature.bind(this);

	    this.showDsVerifyResults = this.showDsVerifyResults.bind(this);
	    this.verifyData = this.verifyData.bind(this);

	    this.deleteInputFile = this.deleteInputFile.bind(this);
		this.deleteInputSignature = this.deleteInputSignature.bind(this);
		this.deleteSignature = this.deleteSignature.bind(this);

		this.checkIndexToRemove = this.checkIndexToRemove.bind(this);

		this.clearUp = this.clearUp.bind(this);
		this.downloadSignature = this.downloadSignature.bind(this);
	}

	componentDidMount() {

	}

	modifySignature () {
		var contextId, dataId, dataToSend, data, _this = this;

		this.props.actions.setProcessing(true);

		this.props.actions.createContext(this.props.base.serviceUrl, this.props.base.token)
		.then((response) => {
			contextId = response.contextId;

			if (this.props.unityReducer.inputFile !== null) {
				this.props.actions.uploadContextDataBinary(this.props.base.serviceUrl, contextId, this.props.unityReducer.inputFile, this.props.base.token)
				.then((response) => {
					console.log(response)

					function getBase64(file) {
					  return new Promise((resolve, reject) => {
					    const reader = new FileReader();
					    reader.readAsDataURL(file);
					    reader.onload = () => {
					      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
					      if ((encoded.length % 4) > 0) {
					        encoded += '='.repeat(4 - (encoded.length % 4));
					      }
					      resolve(encoded);
					    };
					    reader.onerror = error => reject(error);
					  });
					}

					async function Main() {
		        		const file = (_this.props.unityReducer.resultedData !== null ? _this.props.unityReducer.resultedData : _this.props.unityReducer.inputSignature);

		        		if (file.name === undefined) {
					    	data = file.base64Data
					    } else {
					    	data = await getBase64(file);
			   				console.log(await getBase64(file));
					    }
					}

					Main()
					.then(() => {
						dataToSend = {
							"requestedSignerIndexToRemove" : [],
						    "requestedSignatureFormat" : this.props.unityReducer.cadesType,
						    "requestedSignatureType" : this.props.unityReducer.signatureType,
						    "signature" : data,
						    "timestampVerifyOption" : null,
						    "isNokkCompatible" : false
						}

						this.props.actions.modifySignature(this.props.base.serviceUrl, contextId, dataToSend, this.props.base.token)
						.then((response) => {
							console.log(response)
							if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
								var msg = response.json.message + "\n" + response.json.techMessage
								alert(msg);
								this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setProcessing(false);
								})
							} else {
								dataId = response.dataId
								this.props.actions.getDataByIdBase64(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setResultedDataUnity(response)
									this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
									.then((response) => {
										console.log(response)
										this.props.actions.setProcessing(false);
										this.verifyData(this.props.unityReducer.resultedData)
									})
								})
							}
						})
					})
				})
			} else {
				function getBase64(file) {
				  return new Promise((resolve, reject) => {
				    const reader = new FileReader();
				    reader.readAsDataURL(file);
				    reader.onload = () => {
				      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
				      if ((encoded.length % 4) > 0) {
				        encoded += '='.repeat(4 - (encoded.length % 4));
				      }
				      resolve(encoded);
				    };
				    reader.onerror = error => reject(error);
				  });
				}

				async function Main() {
	        		const file = (_this.props.unityReducer.resultedData !== null ? _this.props.unityReducer.resultedData : _this.props.unityReducer.inputSignature);

	        		if (file.name === undefined) {
				    	data = file.base64Data
				    } else {
				    	data = await getBase64(file);
		   				console.log(await getBase64(file));
				    }
				}

				Main()
				.then(() => {
					dataToSend = {
						"requestedSignerIndexToRemove" : [],
					    "requestedSignatureFormat" : this.props.unityReducer.cadesType,
					    "requestedSignatureType" : this.props.unityReducer.signatureType,
					    "signature" : data,
					    "timestampVerifyOption" : null,
					    "isNokkCompatible" : false
					}

					this.props.actions.modifySignature(this.props.base.serviceUrl, contextId, dataToSend, this.props.base.token)
					.then((response) => {
						console.log(response)
						if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
							var msg = response.json.message + "\n" + response.json.techMessage
							alert(msg);
							this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.setProcessing(false);
							})
						} else {
							dataId = response.dataId
							this.props.actions.getDataByIdBase64(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.setResultedDataUnity(response)
								this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setProcessing(false);
									this.verifyData(this.props.unityReducer.resultedData)
								})
							})
						}
					})
				})
			}
		})
	}

	dsTypeHendler(e) {
		if (this.props.base.processing) {
			return;
		}

		this.props.actions.setSignatureTypeUnity(e.target.value)
	}

	cadesTypeHendler(e) {
		if (this.props.base.processing) {
			return;
		}

		this.props.actions.setCadesTypeUnity(e.target.value)
	}

	deleteInputFile () {
		this.props.actions.setInputFileUnity(null)
		this.props.actions.setFileSignsUnity([])
		this.props.actions.setResponseSignatureTypeUnity(null)
		this.props.actions.setRequestedSignerIndexToRemove([])
	}

	deleteSignature () {
		this.props.actions.setInputSignatureUnity(null)
		this.props.actions.setFileSignsUnity([])
		this.props.actions.setResponseSignatureTypeUnity(null)
		this.props.actions.setRequestedSignerIndexToRemove([])
	}

	deleteInputSignature () {
		var contextId, dataId, dataToSend, data, _this = this;

		this.props.actions.setProcessing(true);

		this.props.actions.createContext(this.props.base.serviceUrl, this.props.base.token)
		.then((response) => {
			contextId = response.contextId;

			if (this.props.unityReducer.inputFile !== null) {
				this.props.actions.uploadContextDataBinary(this.props.base.serviceUrl, contextId, this.props.unityReducer.inputFile, this.props.base.token)
				.then((response) => {
					console.log(response)

					function getBase64(file) {
					  return new Promise((resolve, reject) => {
					    const reader = new FileReader();
					    reader.readAsDataURL(file);
					    reader.onload = () => {
					      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
					      if ((encoded.length % 4) > 0) {
					        encoded += '='.repeat(4 - (encoded.length % 4));
					      }
					      resolve(encoded);
					    };
					    reader.onerror = error => reject(error);
					  });
					}

					async function Main() {
		        		const file = (_this.props.unityReducer.resultedData !== null ? _this.props.unityReducer.resultedData : _this.props.unityReducer.inputSignature);

		        		if (file.name === undefined) {
					    	data = file.base64Data
					    } else {
					    	data = await getBase64(file);
			   				console.log(await getBase64(file));
					    }
					}

					Main()
					.then(() => {
						dataToSend = {
							"requestedSignerIndexToRemove" : this.props.unityReducer.requestedSignerIndexToRemove,
						    "signature" : data,
						    "timestampVerifyOption" : null,
						    "isNokkCompatible" : false
						}

						this.props.actions.modifySignature(this.props.base.serviceUrl, contextId, dataToSend, this.props.base.token)
						.then((response) => {
							console.log(response)
							if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
								var msg = response.json.message + "\n" + response.json.techMessage
								alert(msg);
								this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setRequestedSignerIndexToRemove([])
									this.props.actions.setProcessing(false);
								})
							} else {
								dataId = response.dataId
								this.props.actions.getDataByIdBase64(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setResultedDataUnity(response)
									this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
									.then((response) => {
										console.log(response)
										this.props.actions.setRequestedSignerIndexToRemove([])
										this.props.actions.setProcessing(false);
										this.verifyData(this.props.unityReducer.resultedData)
									})
								})
							}
						})
					})
				})
			} else {
				function getBase64(file) {
				  return new Promise((resolve, reject) => {
				    const reader = new FileReader();
				    reader.readAsDataURL(file);
				    reader.onload = () => {
				      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
				      if ((encoded.length % 4) > 0) {
				        encoded += '='.repeat(4 - (encoded.length % 4));
				      }
				      resolve(encoded);
				    };
				    reader.onerror = error => reject(error);
				  });
				}

				async function Main() {
	        		const file = (_this.props.unityReducer.resultedData !== null ? _this.props.unityReducer.resultedData : _this.props.unityReducer.inputSignature);

	        		if (file.name === undefined) {
				    	data = file.base64Data
				    } else {
				    	data = await getBase64(file);
		   				console.log(await getBase64(file));
				    }
				}

				Main()
				.then(() => {
					dataToSend = {
						"requestedSignerIndexToRemove" : this.props.unityReducer.requestedSignerIndexToRemove,
					    "signature" : data,
					    "timestampVerifyOption" : null,
					    "isNokkCompatible" : false
					}

					this.props.actions.modifySignature(this.props.base.serviceUrl, contextId, dataToSend, this.props.base.token)
					.then((response) => {
						console.log(response)
						if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
							var msg = response.json.message + "\n" + response.json.techMessage
							alert(msg);
							this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.setRequestedSignerIndexToRemove([])
								this.props.actions.setProcessing(false);
							})
						} else {
							dataId = response.dataId
							this.props.actions.getDataByIdBase64(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.setResultedDataUnity(response)
								this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setRequestedSignerIndexToRemove([])
									this.props.actions.setProcessing(false);
									this.verifyData(this.props.unityReducer.resultedData)
								})
							})
						}
					})
				})
			}
		})
	}

	formatDate(dateInString) {
        var localeDateOptions = {
            "day": "numeric",
            "month": "numeric",
            "year": "numeric",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit",
            "timeZoneName": "short"
        }

        return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
    }

    checkIndexToRemove (e) {
    	var indexiesArray = this.props.unityReducer.requestedSignerIndexToRemove, inputValue;

    	inputValue = parseInt(e.target.value, 10);

    	if (!indexiesArray.includes(inputValue)) {
    		indexiesArray.push(inputValue)
    	} else {
    		const index = indexiesArray.indexOf(inputValue);
			if (index > -1) {
			  indexiesArray.splice(index, 1);
			}
    	}

    	this.props.actions.setRequestedSignerIndexToRemove(indexiesArray)
    }

    openInfo (index) {
    	let tempState = this.state.dsInfos

    	tempState[index] = !tempState[index]
    	this.setState({dsInfos: tempState})
    }

    verifyData (inputData) {
    	var contextId, dataToSend, data;

    	this.props.actions.setProcessing(true);
    	this.props.actions.setFileSignsUnity([])

    	this.props.actions.createContext(this.props.base.serviceUrl, this.props.base.token)
		.then((response) => {
			contextId = response.contextId;

			function getBase64(file) {
			  return new Promise((resolve, reject) => {
			    const reader = new FileReader();

			    reader.readAsDataURL(file);
			    
			    reader.onload = () => {
			      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
			      if ((encoded.length % 4) > 0) {
			        encoded += '='.repeat(4 - (encoded.length % 4));
			      }
			      resolve(encoded);
			    };
			    reader.onerror = error => reject(error);
			  });
			}

			async function Main() {
        		const file = inputData;

        		if (inputData.name === undefined) {
			    	data = inputData.base64Data
			    } else {
			    	data = await getBase64(file);
	   				console.log(await getBase64(file));
			    }
        		
			}

			Main()
			.then(() => {
				dataToSend = {
				    "signature" : data
				}

				this.props.actions.verifySignature(this.props.base.serviceUrl, contextId, dataToSend, this.props.base.token)
				.then((response) => {
					console.log(response)

					if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8") {
						if (response.json.code === "ESSWISSKNIFECOMMON-8" && this.props.unityReducer.inputFile !== null) {
							this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.createContext(this.props.base.serviceUrl, this.props.base.token)
								.then((response) => {
									contextId = response.contextId;

									this.props.actions.uploadContextDataBinary(this.props.base.serviceUrl, contextId, this.props.unityReducer.inputFile, this.props.base.token)
									.then((response) => {
										console.log(response)
										function getBase64(file) {
										  return new Promise((resolve, reject) => {
										    const reader = new FileReader();
										    reader.readAsDataURL(file);
										    reader.onload = () => {
										      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
										      if ((encoded.length % 4) > 0) {
										        encoded += '='.repeat(4 - (encoded.length % 4));
										      }
										      resolve(encoded);
										    };
										    reader.onerror = error => reject(error);
										  });
										}

										async function Main() {
							        		const file = inputData;

							        		if (inputData.name === undefined) {
										    	data = inputData.base64Data
										    } else {
										    	data = await getBase64(file);
								   				console.log(await getBase64(file));
										    }
										}

										Main()
										.then(() => {
											dataToSend = {
											    "signature" : data
											}

											this.props.actions.verifySignature(this.props.base.serviceUrl, contextId, dataToSend, this.props.base.token)
											.then((response) => {
												console.log(response)

												if (response !== null && response.contentType === "application/ccs.api.error+json;charset=UTF-8" && response.json.code === "ESSWISSKNIFECOMMON-8") {
													
													var msg = response.json.message + "\n" + response.json.techMessage
													alert(msg);

													this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
													.then((response) => {
														console.log(response)
														this.props.actions.setProcessing(false);
													})
												} else {
													this.props.actions.setFileSignsUnity(response)

													this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
													.then((response) => {
														console.log(response)
														this.props.actions.setProcessing(false);
														this.props.actions.setResponseSignatureTypeUnity('DETACHED')
														this.props.actions.setSignatureTypeUnity('DETACHED')
													})
												}
											})
										})
									})
								})
							})
						} else {
							alert(response.json.message);

							this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.setProcessing(false);
							})
						}
					} else {
						this.props.actions.setFileSignsUnity(response)

						this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
						.then((response) => {
							console.log(response)
							this.props.actions.setInputFileUnity(null)
							this.props.actions.setProcessing(false);
							this.props.actions.setResponseSignatureTypeUnity('ATTACHED')
							this.props.actions.setSignatureTypeUnity('ATTACHED')
						})
					}
				})
			})
		})
    }

	handleOnDrop (files) {
		var dataFile, signatureFile;

		this.props.actions.setFileSignsUnity([])
		this.props.actions.setResponseSignatureTypeUnity(null)
		this.props.actions.setRequestedSignerIndexToRemove([])

		dataFile = this.props.unityReducer.inputFile
		signatureFile = this.props.unityReducer.inputSignature

		for (var i = 0; i < files.length; i += 1) {
			if (files[i].name.split('.').pop() !== "p7s") {
				dataFile = files[i];
			} else {
				signatureFile = files[i];
			}
		}

		this.props.actions.setInputFileUnity(dataFile)
		this.props.actions.setInputSignatureUnity(signatureFile)

		if (signatureFile !== null) {
			this.verifyData(signatureFile);
		}
	}

	showDsVerifyResults (verifyResults) {
		let rootDiv = [], divPtblg = [];
		for (var i = 0; i < verifyResults.length; i += 1) {
        	let signerNumber = [];
			let signerStatus = [];
			let signerDetails = [];
			let tsDetails = [];
			let tempString = [];
			let tempState = this.state.dsInfos

			if (verifyResults[i].signatureTimestamps && verifyResults[i].signatureTimestamps.length) {
				for (var j = 0; j < verifyResults[i].signatureTimestamps.length; j += 1) {
					tsDetails.push(this.constructTimestampDetails(verifyResults[i].signatureTimestamps[j], "signature"))
				}
			}

        	signerNumber.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
    			{i18next.t("signature") + (i + 1)}
        	</div>)

        	if (verifyResults[i].status === "SUCCESS") {
        		if (this.state.dsInfos[i] === undefined) {
        			tempState[i] = false;
        			this.setState({dsInfos: tempState})
        		}
        		
    			signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
        			{
        				verifyResults[i].isValid ? i18next.t("heIsValid") : i18next.t("heIsInvalid")
        			}
        			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
	        			{
	        				(verifyResults[i].signerCertificateInfo.extensions.isPrivateKeyOnQSCD === true && verifyResults[i].signerCertificateInfo.extensions.isQualified === true) ? i18next.t("qualifiedTitle") : i18next.t("unqualifiedTitle")
	        			}
	        		</div>
	        		<div>
	        			<input type="checkbox" checked={this.props.unityReducer.requestedSignerIndexToRemove.includes(verifyResults[i].signerIndex) ? true : false} disabled={(this.props.unityReducer.requestedSignerIndexToRemove.length < (this.props.unityReducer.signs.length - 1) || this.props.unityReducer.requestedSignerIndexToRemove.includes(verifyResults[i].signerIndex)) ? false : true} value={verifyResults[i].signerIndex} onChange={this.checkIndexToRemove} />
	        		</div>
        		</div>)
		        
		        signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details no-left-right-padding">
	    			<div>
	    				<span className="font-weight-bold">{ i18next.t("signer")}</span> <span>{verifyResults[i].signerCertificateInfo.subject.commonName }</span>
	    			</div>
	    				{
							verifyResults[i].signerCertificateInfo.subject.ownerOrganization ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("organizationName") }</span> <span>{verifyResults[i].signerCertificateInfo.subject.ownerOrganization }</span>
				        		</div> : null
				        } {
							verifyResults[i].signerCertificateInfo.subject.ownerPosition ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("title") }</span> <span>{verifyResults[i].signerCertificateInfo.subject.ownerPosition }</span>
				        		</div> : null
				        } {
							verifyResults[i].signerCertificateInfo.extensions.drfou ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("drfou") }</span> <span>{verifyResults[i].signerCertificateInfo.extensions.drfou }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerCertificateInfo.extensions.edrpou ? 
					        	<div>
				    				<span className="font-weight-bold">{ i18next.t("edrpou")}</span><span>{ verifyResults[i].signerCertificateInfo.extensions.edrpou }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerCertificateInfo.extensions.eddrun ? 
				        		<div>
				    				<span className="font-weight-bold">{ i18next.t("eddrun")}</span><span>{ verifyResults[i].eddrun }</span>
				        		</div> : null
				        }
	    			<div>
	    				<span className="font-weight-bold">{ i18next.t("ca")}</span><span>{verifyResults[i].signerCertificateInfo.issuer.commonName }</span>
	        		</div>
	        		<div>
	    				<span className="font-weight-bold">{ i18next.t("signatureFormatDs")}</span><span>{i18next.t(verifyResults[i].signatureFormat)}</span>
	        		</div>

	        		<div style={{textAlign: "right", cursor: "pointer"}} onClick={this.openInfo.bind(this, i)}>
	    				<span>{i18next.t("moreInfoTitle")}{
	    					this.state.dsInfos[i] === true
	    					? <i className="fa fa-chevron-up" aria-hidden="true"></i>
	    					: <i className="fa fa-chevron-down" aria-hidden="true"></i>
	    				}
	    				</span>
	        		</div>

	        		{
	        			this.state.dsInfos[i] === true
	        			? <div>
	        				<div>
			        		 	{
						        	verifyResults[i].signerCertificateInfo.serialNumber ? 
				                        <div>
						    				<span className="font-weight-bold">{ i18next.t("certificateSerialNumberTitle")}</span><span>{verifyResults[i].signerCertificateInfo.serialNumber.replace(/\s/g, "") }</span>
						        		</div> : null
			                    }
			                </div>
			    			<div>
			    				{
			    					verifyResults[i].signingDateTime !== undefined && verifyResults[i].signingDateTime.length > 0
			    					? <span><span className="font-weight-bold">{ i18next.t("dsDate")}</span><span>{this.formatDate(verifyResults[i].signingDateTime)}</span></span>
			    					: null
			    				}
			    			</div>
			    			<div>
			    				{
			    					this.constructTimestampDetails(verifyResults[i].contentTimestamp, "content")
			    				}
			    			</div>
			    			<div>
			    				{
			    					tsDetails
				                }
			    			</div>
			    			<div>
			    				{
									verifyResults[i].signerCertificateInfo.extensions.isElectronicStamp !== undefined ?
				                        <div>
						    				<span className="font-weight-bold">{ i18next.t("isElectronicStampTitle") }</span> <span>{verifyResults[i].signerCertificateInfo.extensions.isElectronicStamp === true ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
						        		</div> : null
						        }

			    				{
									verifyResults[i].signerCertificateInfo.extensions.isQualified !== undefined ?
				                        <div>
						    				<span className="font-weight-bold">{ i18next.t("qualifiedCertTitle") }</span> <span>{verifyResults[i].signerCertificateInfo.extensions.isQualified === true ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
						        		</div> : null
						        }
						        {
									verifyResults[i].signerCertificateInfo.extensions.isPrivateKeyOnQSCD !== undefined ?
				                        <div>
						    				<span className="font-weight-bold">{ i18next.t("QSCDTitle") }</span> <span>{verifyResults[i].signerCertificateInfo.extensions.isPrivateKeyOnQSCD === true ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
						        		</div> : null
						        }
						        {
									verifyResults[i].signerCertificateInfo.extensions.qscdTypeName !== undefined ?
				                        <div>
						    				<span className="font-weight-bold">{ i18next.t("QSCDTypeTitle") }</span> <span>{verifyResults[i].signerCertificateInfo.extensions.qscdTypeName === null ? i18next.t("notDefined") : verifyResults[i].signerCertificateInfo.extensions.qscdTypeName}</span>
						        		</div> : null
						        }
			    			</div>
			    		</div>
	        			: null
	        		}

	    		</div>)

	        	divPtblg.push(<div className="ptblg-default" key={"divPtblg" + i} style={{marginLeft: "-10px", marginRight: "-10px", marginTop: "-10px"}}>
	        		{
						 <div className={(verifyResults[i].isValid && tempString.length === 0) ? "alert alert-success" : "alert alert-warning"}>
			        		<div className="row">
			        			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 no-left-right-padding" style={{paddingLeft: "11px"}}>{signerNumber} {signerStatus}</div> <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-left-no-right-padding">{signerDetails}</div>
			        		</div>
			        		<div>
				        		{
				        			tempString
				        		}
				        	</div>
			        	</div>
			        }
	        	</div>)
	        } else {
		        signerStatus.push(<div>
			        	<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
		        			{i18next.t("notDefined")}
		        		</div>
		        		<div>
		        			<input type="checkbox" checked={this.props.unityReducer.requestedSignerIndexToRemove.includes(verifyResults[i].signerIndex) ? true : false} disabled={(this.props.unityReducer.requestedSignerIndexToRemove.length < (this.props.unityReducer.signs.length - 1) || this.props.unityReducer.requestedSignerIndexToRemove.includes(verifyResults[i].signerIndex)) ? false : true} value={verifyResults[i].signerIndex} onChange={this.checkIndexToRemove} />
		        		</div>
		        	</div>)
		        signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details no-left-right-padding break-word">
		    			<div>
		    				{ verifyResults[i].failureCause }
		    				{
					            verifyResults[i].isUntrustedValid
					            ? <div><p></p><div><b>{i18next.t("signatureValidWithUntrustedTime")}</b></div></div>
					            : null
					        }
		    			</div>
	    			</div>)
	        	divPtblg.push(<div className="ptblg-default" key={"divPtblg" + i} style={{marginLeft: "-10px", marginRight: "-10px", marginTop: "-10px"}}>
	        		<div className={(verifyResults[i].isValid && tempString.length === 0) ? "alert alert-success" : "alert alert-warning"}>
		        		<div className="row">
		        			<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 no-left-no-right-padding">{signerNumber} {signerStatus}</div> <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 no-left-no-right-padding">{signerDetails}</div>
		        		</div>
		        	</div>
	        	</div>)
            }
        }
        rootDiv.push(<div id="divPtblg" style={{fontSize: "14px", marginLeft: "12px", marginRight: "12px"}}>{divPtblg}</div>)
        return rootDiv
	}

	handleDeleteFile () {
		this.props.actions.setInputSignatureUnity(null)
		this.props.actions.setFileSignsUnity([])
	}

	clearUp () {
		this.props.actions.setInputSignatureUnity(null)
		this.props.actions.setFileSignsUnity([])
		this.props.actions.setResponseSignatureTypeUnity(null)
		this.props.actions.setRequestedSignerIndexToRemove([])
		this.props.actions.setInputFileUnity(null)
		this.props.actions.setResultedDataUnity(null)
	}

	constructTimestampDetails(aTs, aTsType) {
        let contentTs = i18next.t("dataTs");
        let signatureTs = i18next.t("dsTs");
        let tsaDS = i18next.t("tsaCertificateSerialNumberDS");
        let tsa = i18next.t("tsaCertificateSerialNumber");
        let result = [];

        let tsTypeString = function () {
            if (aTsType === "content") {
                return contentTs;
            }
            if (aTsType === "signature") {
                return signatureTs;
            }
            return "";
        }

        let tsaTsTypeString = function () {
            if (aTsType === "content") {
                return tsa;
            }
            if (aTsType === "signature") {
                return tsaDS;
            }
            return "";
        }
        
        if (aTs && aTs.dateTime) {
            if (aTs.isFailure) {
                result.push(<div>{tsTypeString() + i18next.t("errorWhileVerifying")}</div>);
            } else {
            	result.push(
	            	<div>
	            		<div>
	            			<span className="font-weight-bold">{tsTypeString()}</span>
	            			{
			            		": "
			                    + (aTs.isValid ? i18next.t("sheIsValid") : i18next.t("sheIsInvalid"))
			                    + "; "
			                    + this.formatDate(aTs.dateTime)
		                	}
		                	{
		                		aTs.tsaCertificateSerialNumber
		                		? <div><span className="font-weight-bold">{tsaTsTypeString()}</span>
				                	{
					                    aTs.tsaCertificateSerialNumber
				                	}
				                </div>
				                : null
		                	}	                	
	            		</div>
	                </div>
                );
            }
        }
        return result;
    }

    downloadSignature () {
    	var blob, name;
    	const b64toBlob = (b64Data, contentType, sliceSize=512) => {
		  const byteCharacters = atob(b64Data);
		  const byteArrays = [];

		  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		    const slice = byteCharacters.slice(offset, offset + sliceSize);

		    const byteNumbers = new Array(slice.length);
		    for (let i = 0; i < slice.length; i++) {
		      byteNumbers[i] = slice.charCodeAt(i);
		    }

		    const byteArray = new Uint8Array(byteNumbers);
		    byteArrays.push(byteArray);
		  }

		  const blob = new Blob(byteArrays, {type: contentType});
		  return blob;
		}

		blob = b64toBlob(this.props.unityReducer.resultedData.base64Data, {type : 'application/pkcs7-signature'});

		name = this.props.unityReducer.inputSignature.name.substring(0, this.props.unityReducer.inputSignature.name.lastIndexOf('.')) || this.props.unityReducer.inputSignature.name;
		saveAs(blob, name  + "-m.p7s")
    }

	render() {

		const isDeleteEnabled = (this.props.unityReducer.requestedSignerIndexToRemove.length > 0 && !this.props.base.processing) ? true : false
		const isModifyEnabled = (this.props.unityReducer.signs.length > 0 && !this.props.base.processing) ? true : false
		const isCleanUpEnabled = ((this.props.unityReducer.requestedSignerIndexToRemove.length > 0 && !this.props.base.processing) || (this.props.unityReducer.signs.length > 0 && !this.props.base.processing) || (this.props.unityReducer.inputSignature !== null) || (this.props.unityReducer.inputFile !== null)) ? true : false

		return (
			<div className="row" style={{marginTop: "10px"}}>
				<div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
					<fieldset className="fieldSet">
						<legend className="legend">{i18next.t("expectedSignatures")}</legend>
							<fieldset className="fieldSet">
								<legend className="legend">{i18next.t("signatureType")}</legend>
								<div className="form-check">
		                            <div className="radio ml-tool-panel">
		                                <label title={i18next.t("attachedDsTooltip")}>
		                                    <input className="form-check-input" value="ATTACHED" type="radio" checked={this.props.unityReducer.signatureType === 'ATTACHED'} onChange={this.dsTypeHendler} />
		                                    <span className={(this.props.unityReducer.responsedSignatureType !== null && this.props.unityReducer.responsedSignatureType === 'ATTACHED') ? "form-check-label font-weight-bold" : "form-check-label"}>{i18next.t("attachedDsTitle")}</span>
		                                </label>
		                            </div>
		                            <div className="radio ml-tool-panel">
		                                <label title={i18next.t("detachedDsTooltip")}>
		                                    <input className="form-check-input" value="DETACHED" type="radio" checked={this.props.unityReducer.signatureType === 'DETACHED'} onChange={this.dsTypeHendler} />
		                                    <span className={(this.props.unityReducer.responsedSignatureType !== null && this.props.unityReducer.responsedSignatureType === 'DETACHED') ? "form-check-label font-weight-bold" : "form-check-label"}>{i18next.t("detachedDsTitle")}</span>
		                                </label>
		                            </div>
		                        </div>
							</fieldset>
							<fieldset className="fieldSet">
								<legend className="legend">{i18next.t("signatureFormat")}</legend>
								<div className="form-check">
			                        <div className="radio ml-tool-panel">
			                            <label title={i18next.t("cadesBesTooltip")}>
			                                <input className="form-check-input" value="CADES_BES" type="radio" checked={this.props.unityReducer.cadesType === 'CADES_BES'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("dsBaseTitle")}</span>
			                            </label>
			                        </div>
			                        <div className="radio ml-tool-panel">
			                            <label title={i18next.t("cadesTTooltip")}>
			                                <input className="form-check-input" value="CADES_T" type="radio" checked={this.props.unityReducer.cadesType === 'CADES_T'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("dsTTitle")}</span>
			                            </label>
			                        </div>
			                        <div className="radio ml-tool-panel">
			                            <label title={i18next.t("cadesXLongTooltip")}>
			                                <input className="form-check-input" value="CADES_X_LONG" type="radio" checked={this.props.unityReducer.cadesType === 'CADES_X_LONG'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("dsLongTitle")}</span>
			                            </label>
			                        </div>
			                    </div>
							</fieldset>
					</fieldset>
					<div style={{marginTop: "10px"}}>
						<button className="btn btn-default-color btn-block" title={i18next.t("unityTooltipText")} onClick={this.deleteInputSignature} disabled={!isDeleteEnabled}>
							{
								i18next.t("delete")
							}
						</button>
					</div>
					<div style={{marginTop: "10px"}}>
						<button className="btn btn-default-color btn-block" title={i18next.t("unityTooltipText")} onClick={this.modifySignature} disabled={!isModifyEnabled}>
							{
								i18next.t("convert")
							}
						</button>
					</div>
					<div style={{marginTop: "10px"}}>
						<button className="btn btn-default-color btn-block" onClick={this.clearUp} disabled={!isCleanUpEnabled}>
							{
								i18next.t("cleanup")
							}
						</button>
					</div>
				</div>
				<div className="col-lg-9 col-md-9 col-xs-12 col-sm-12">
					<fieldset className="fieldSet">
						<legend className="legend">{i18next.t("initialFilesAndSignatures")}</legend>
						{
							<div>
								<div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-left-right-padding" style={{marginLeft: "0px"}}>
									<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingTop: "10px"}}>
										<Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop} disabled={this.props.base.processing ? true : false}>
					                        {({getRootProps, getInputProps}) => (
					                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone-md" id="dropFile">
					                                <input {...getInputProps()} />
					                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitle")}</p>
					                            </div>
					                        )}
					                    </Dropzone>
					                </div>
								</div>
								{
									this.props.unityReducer.inputFile !== null || this.props.unityReducer.inputSignature !== null
									? <div className="row" style={{marginRight: "0px", marginTop: "10px"}}>
										{
											this.props.unityReducer.inputFile !== null
											? <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingTop: "10px"}}>
							                	{
							                		this.props.unityReducer.inputFile !== null
							                		? <label className="col-12" style={{fontWeight: "600"}}>{i18next.t("dataFile")}</label>
						                			: null
							                	}
							                	{
							                		this.props.unityReducer.inputFile !== null
							                		? <label className="col-12" style={{cursor: "pointer"}} onClick={this.deleteInputFile}>{this.props.unityReducer.inputFile.name}</label>
							                		: null
							                	}
							                </div>
											: null
										}
						                
						                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingTop: "10px"}}>
						                	{
						                		this.props.unityReducer.inputSignature !== null
						                		? <label className="col-12" style={{fontWeight: "600"}}>{i18next.t("signatureFiles")}</label>
					                			: null
						                	}
						                	{
						                		this.props.unityReducer.inputSignature !== null
						                		? <label className="col-12" style={{cursor: "pointer"}} onClick={this.deleteSignature}>{this.props.unityReducer.inputSignature.name}</label>
						                		: null
						                	}
						                </div>
					                </div>
									: <div className="row" style={{marginTop: "32px"}}>

									</div>
								}
				            </div>
						}
					</fieldset>

					{
						this.props.unityReducer.signs !== undefined && this.props.unityReducer.signs.length > 0
						? <fieldset className="fieldSet">
							<legend className="legend">{i18next.t("signersInfo")}</legend>
							{
								this.props.unityReducer.signs !== undefined && this.props.unityReducer.signs.length > 0
								? <div>
									{
										this.showDsVerifyResults(this.props.unityReducer.signs)
				                	}
								  </div>
								: null
							}

						</fieldset>
						: null
					}

					<fieldset className="fieldSet" style={{minHeight: "100px"}}>
						<legend className="legend">{i18next.t("resultFilesAndSigns")}</legend>
							<div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{marginRight: "0px", marginTop: "10px"}}>
				                {/*<div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">

				                </div>*/}
				                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
				                	{
				                		this.props.unityReducer.resultedData !== null
				                		? <label className="col-12 form-check-label font-weight-bold" style={{cursor: "pointer", paddingLeft: "0px"}} onClick={this.downloadSignature}>{i18next.t("downloadSignedData")}</label>
				                		: null
				                	}
				                </div>
			                </div>
					</fieldset>
				</div>
			</div>
	    );
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        unityReducer: state.unityReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setSignatureTypeUnity, setCadesTypeUnity, setInputFileUnity, setFileSignsUnity,
    	createContext, deleteContext, uploadContextDataBinary, getDataByIdBase64,
    	getDataByIdBinary, verifySignature, modifySignature, setInputSignatureUnity,
    	setResponseSignatureTypeUnity, setRequestedSignerIndexToRemove, setProcessing,
    	setResultedDataUnity
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Unity);