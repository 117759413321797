import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import saveAs from 'file-saver';

import { setSignatureType, setCadesType, setFilelist, setDataFile, setProcessing, setResultedDataFuse } from '../actions/localStates'
import { createContext, deleteContext, uploadContextDataBinary, combineSignatures, getDataByIdBase64, getDataByIdBinary } from '../actions/api'

import i18next from 'i18next'

import Dropzone from 'react-dropzone';
const dropzoneRef = createRef();

class Fuse extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	files: this.props.fuseReducer.fileList || [],
			sizeOfFiles: 0
	    }

	    this.dsTypeHendler = this.dsTypeHendler.bind(this)
	    this.cadesTypeHendler = this.cadesTypeHendler.bind(this)

	    this.handleOnDrop = this.handleOnDrop.bind(this);

	    this.combineSignatures = this.combineSignatures.bind(this);
	    this.downloadSignature = this.downloadSignature.bind(this);

	    this.clearUp = this.clearUp.bind(this);

	    this.deleteInputFile = this.deleteInputFile.bind(this);
	}

	componentDidMount() {

	}

	deleteInputFile () {
		if (this.props.base.processing) {
			return;
		}

		this.props.actions.setDataFile(null);
	}

	deleteSignatureFile (value) {
		var signatureArray
		if (this.props.base.processing) {
			return;
		}

		signatureArray = this.props.fuseReducer.fileList

		for(var i = 0; i < signatureArray.length; i++) {
		    if(i === value) {
		        signatureArray.splice(i, 1);
		        break;
		    }
		}

		this.setState({"files": signatureArray}, () => {
			this.props.actions.setFilelist(this.state.files);
		})
	}

	clearUp () {
		this.props.actions.setDataFile(null)
		this.props.actions.setFilelist([])
		this.props.actions.setResultedDataFuse(null)
		this.setState({"files": []})
		this.setState({"sizeOfFiles": 0})
	}

	downloadSignature () {
    	var blob, name;
    	const b64toBlob = (b64Data, contentType, sliceSize=512) => {
		  const byteCharacters = atob(b64Data);
		  const byteArrays = [];

		  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		    const slice = byteCharacters.slice(offset, offset + sliceSize);

		    const byteNumbers = new Array(slice.length);
		    for (let i = 0; i < slice.length; i++) {
		      byteNumbers[i] = slice.charCodeAt(i);
		    }

		    const byteArray = new Uint8Array(byteNumbers);
		    byteArrays.push(byteArray);
		  }

		  const blob = new Blob(byteArrays, {type: contentType});
		  return blob;
		}

		blob = b64toBlob(this.props.fuseReducer.resultedDataFuse.base64Data, {type : 'application/pkcs7-signature'});

		if (this.props.fuseReducer.dataFile !== null) {
			name = this.props.fuseReducer.dataFile.name.substring(0, this.props.fuseReducer.dataFile.name.lastIndexOf('.')) || this.props.fuseReducer.dataFile.name;
		} else {
			name = "combinedSignatures";
		}
		
		saveAs(blob, name  + "-c.p7s")
    }

	combineSignatures () {
		var contextId, dataId, tempArr = [], _this = this;

		this.props.actions.setProcessing(true);
		this.props.actions.createContext(this.props.base.serviceUrl, this.props.base.token)
		.then((response) => {
			console.log(response)
			contextId = response.contextId;
			if (this.props.fuseReducer.dataFile !== null) {
				this.props.actions.uploadContextDataBinary(this.props.base.serviceUrl, contextId, this.props.fuseReducer.dataFile, this.props.base.token)
				.then((response) => {
					console.log(response)
					
					const toBase64 = file => new Promise((resolve, reject) => {
					    const reader = new FileReader();
					    reader.readAsDataURL(file);
					    reader.onload = () => {
					    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
					      if ((encoded.length % 4) > 0) {
					        encoded += '='.repeat(4 - (encoded.length % 4));
					      }
					      resolve(encoded);
					    };
					    reader.onerror = error => reject(error);
					});

					async function Main() {
						for (var i = 0; i < _this.props.fuseReducer.fileList.length; i += 1) {
			        		const file = _this.props.fuseReducer.fileList[i];
			        		tempArr.push(await toBase64(file));
				   			console.log(await toBase64(file));
						}
					}

					Main()
					.then(() => {
						var data = {
						  "signatures": tempArr
						}
						this.props.actions.combineSignatures(this.props.base.serviceUrl, contextId, data, this.props.base.token)
						.then((response) => {
							console.log(response)
							dataId = response.dataId
							this.props.actions.getDataByIdBase64(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.setResultedDataFuse(response);
								this.props.actions.getDataByIdBinary(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
									.then((response) => {
										console.log(response)
										this.props.actions.setProcessing(false);
									})				
								})
							})
						})
					})
				})
			} else {
				const toBase64 = file => new Promise((resolve, reject) => {
				    const reader = new FileReader();
				    reader.readAsDataURL(file);
				    reader.onload = () => {
				      let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
				      if ((encoded.length % 4) > 0) {
				        encoded += '='.repeat(4 - (encoded.length % 4));
				      }
				      resolve(encoded);
				    };
				    reader.onerror = error => reject(error);
				});

				async function Main() {
					for (var i = 0; i < _this.props.fuseReducer.fileList.length; i += 1) {
		        		const file = _this.props.fuseReducer.fileList[i];
		        		tempArr.push(await toBase64(file));
			   			console.log(await toBase64(file));
					}
				}

				Main()
				.then(() => {
					var data = {
					  "signatures": tempArr
					}
					this.props.actions.combineSignatures(this.props.base.serviceUrl, contextId, data, this.props.base.token)
					.then((response) => {
						console.log(response)
						dataId = response.dataId
						this.props.actions.getDataByIdBase64(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
						.then((response) => {
							console.log(response)
							this.props.actions.setResultedDataFuse(response);
							this.props.actions.getDataByIdBinary(this.props.base.serviceUrl, contextId, dataId, this.props.base.token)
							.then((response) => {
								console.log(response)
								this.props.actions.deleteContext(this.props.base.serviceUrl, contextId, this.props.base.token)
								.then((response) => {
									console.log(response)
									this.props.actions.setProcessing(false);
								})				
							})
						})
					})
				})
			}
			
		})
	}

	dsTypeHendler(e) {
		this.props.actions.setSignatureType(e.target.value)
	}

	cadesTypeHendler(e) {
		this.props.actions.setCadesType(e.target.value)
	}

	handleOnDrop (files) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		let dataFile = this.props.fuseReducer.dataFile || null;

		this.props.actions.setResultedDataFuse(null)

		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}

		if (files[0] !== undefined && files.length > 0) {

			if (allFilesSize < 104857600) {
				for (var i = 0; i < files.length; i += 1) {
					if (allFilesSize + (files[i].size || files[i].fileSize) < 104857600) {
						if (files[i].name.split('.').pop() !== "p7s") {
							dataFile = files[i];
							allFilesSize += files[i].size
							this.setState({"sizeOfFiles": allFilesSize})
						} else {
							fileList.push(files[i])
							allFilesSize += files[i].size
							this.setState({"sizeOfFiles": allFilesSize})
						}
						
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilelist(this.state.files);
					this.props.actions.setDataFile(dataFile);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	render() {
		const isFuseEnabled = (this.props.fuseReducer.fileList.length > 1 && !this.props.base.processing) ? true : false;

		const isCleanUpEnabled = ((this.props.fuseReducer.fileList.length > 0 && !this.props.base.processing) || (this.props.fuseReducer.dataFile !== null && !this.props.base.processing)) ? true : false;

		return (
			<div className="row" style={{marginTop: "10px"}}>
				<div className="col-lg-3 col-md-3 col-xs-12 col-sm-12">
					{/*<fieldset className="fieldSet">
						<legend className="legend">{i18next.t("expectedSignatures")}</legend>
							<fieldset className="fieldSet">
								<legend className="legend">{i18next.t("signatureType")}</legend>
								<div className="form-check">
		                            <div className="radio ml-tool-panel">
		                                <label>
		                                    <input className="form-check-input" value="attached" type="radio" checked={this.props.fuseReducer.signatureType === 'attached'} onChange={this.dsTypeHendler} />
		                                    <span className="form-check-label">{i18next.t("attachedDsTitle")}</span>
		                                </label>
		                            </div>
		                            <div className="radio ml-tool-panel">
		                                <label>
		                                    <input className="form-check-input" value="detached" type="radio" checked={this.props.fuseReducer.signatureType === 'detached'} onChange={this.dsTypeHendler} />
		                                    <span className="form-check-label">{i18next.t("detachedDsTitle")}</span>
		                                </label>
		                            </div>
		                        </div>
							</fieldset>
							<fieldset className="fieldSet">
								<legend className="legend">{i18next.t("signatureFormat")}</legend>
								<div className="form-check">
			                        <div className="radio ml-tool-panel">
			                            <label>
			                                <input className="form-check-input" value="CAdESBES" type="radio" checked={this.props.fuseReducer.cadesType === 'CAdESBES'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("dsBaseTitle")}</span>
			                            </label>
			                        </div>
			                        <div className="radio ml-tool-panel">
			                            <label>
			                                <input className="form-check-input" value="CAdEST" type="radio" checked={this.props.fuseReducer.cadesType === 'CAdEST'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("dsTTitle")}</span>
			                            </label>
			                        </div>
			                        <div className="radio ml-tool-panel">
			                            <label>
			                                <input className="form-check-input" value="CAdESXLong" type="radio" checked={this.props.fuseReducer.cadesType === 'CAdESXLong'} onChange={this.cadesTypeHendler} />
			                                <span className="form-check-label">{i18next.t("dsLongTitle")}</span>
			                            </label>
			                        </div>
			                    </div>
							</fieldset>
					</fieldset>*/}
					<div style={{marginTop: "10px"}}>
						<button className="btn btn-default-color btn-block" title={i18next.t("fuseTooltipText")} onClick={this.combineSignatures} disabled={!isFuseEnabled}>
							{
								i18next.t("fuse")
							}
						</button>
					</div>
					<div style={{marginTop: "10px"}}>
						<button className="btn btn-default-color btn-block" onClick={this.clearUp} disabled={!isCleanUpEnabled}>
							{
								i18next.t("cleanup")
							}
						</button>
					</div>
				</div>
				<div className="col-lg-9 col-md-9 col-xs-12 col-sm-12">
					<fieldset className="fieldSet" style={{minHeight: "150px"}}>
						<legend className="legend">{i18next.t("initialFilesAndSignatures")}</legend>
						<div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12 no-left-right-padding" style={{marginLeft: "0px"}}>
							<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingTop: "10px"}}>
								<Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop} disabled={this.props.base.processing ? true : false}>
			                        {({getRootProps, getInputProps}) => (
			                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone-md" id="dropFile">
			                                <input {...getInputProps()} />
			                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitle")}</p>
			                            </div>
			                        )}
			                    </Dropzone>
			                </div>
			                <div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{marginRight: "0px", marginTop: "10px"}}>

				                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingTop: "10px"}}>
				                	{
				                		this.props.fuseReducer.dataFile !== null
				                		? <label className="col-12" style={{fontWeight: "600"}}>{i18next.t("dataFile")}</label>
				                		: null
				                	}
				                	{
				                		this.props.fuseReducer.dataFile !== null
				                		? <label className="col-lg-12 col-md-12 col-xs-12 col-sm-12 break-word">{this.props.fuseReducer.dataFile.name + " "}<i className="fa fa-trash" onClick={this.deleteInputFile} style={{"cursor": "pointer"}}></i></label>
				                		: null
				                	}
				                </div>
				                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{paddingTop: "10px"}}>
				                	{
				                		this.props.fuseReducer.fileList.length > 0
				                		? <label className="col-12" style={{fontWeight: "600"}}>{i18next.t("signatureFiles")}</label>
				                		: null
				                	}
				                	{
				                		this.props.fuseReducer.fileList.map((n, index) => {
				                			return <label key={index} className="col-lg-12 col-md-12 col-xs-12 col-sm-12 break-word">{n.name + " "}<i className="fa fa-trash" onClick={this.deleteSignatureFile.bind(this, index)} style={{"cursor": "pointer"}}></i></label>
				                		})
				                	}
				                </div>
			                </div>
						</div>
					</fieldset>
					<fieldset className="fieldSet" style={{minHeight: "100px"}}>
						<legend className="legend">{i18next.t("resultFilesAndSigns")}</legend>
							<div className="row col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{marginRight: "0px", marginTop: "10px"}}>
				                {/*<div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">

				                </div>*/}
				                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
				                	{
				                		this.props.fuseReducer.resultedDataFuse !== null
				                		? <label className="col-12 form-check-label font-weight-bold" style={{cursor: "pointer"}} onClick={this.downloadSignature}>{i18next.t("downloadSignedData")}</label>
				                		: null
				                	}
				                </div>
			                </div>
					</fieldset>
				</div>
			</div>
	    );
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        fuseReducer: state.fuseReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setSignatureType, setCadesType,
    	setFilelist, setDataFile, setProcessing,
    	createContext, deleteContext,
    	uploadContextDataBinary, combineSignatures,
    	getDataByIdBase64, getDataByIdBinary,
    	setResultedDataFuse
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Fuse);